import { Box, BoxProps, Divider } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

export const Footer = () => {
  const path = useLocation().pathname;
  const query = useLocation().search;

  const JBox: React.FC<BoxProps> = ({ children, ...rest }) => {
    return (
      <Box
        display={!query.includes("lang=en") ? "block" : "none"}
        fontFamily={"Noto Sans JP Variable"}
        fontSize={["0.9em", "0.8em"]}
        fontWeight={"light"}
        {...rest}
      >
        {children}
      </Box>
    );
  };

  const EBox: React.FC<BoxProps> = ({ children, ...rest }) => {
    return (
      <Box display={query.includes("lang=en") ? "block" : "none"} fontFamily={"Lato"} fontSize={["0.95em", "0.85em"]} fontWeight={"light"} {...rest}>
        {children}
      </Box>
    );
  };

  return (
    <Box className="description" mb={[0]} bgColor={"black"} mt={path.includes("chat") ? "0" : "2em"}>
      <Box maxW={"900px"} mx={"auto"} pt={"1em"} pb={"3em"} px={"1em"}>
        <Box>
          <Box>
            <Box display={path.includes("chat") ? "none" : "block"}>
              <Box>
                <JBox>
                  1996年版は、財団法人ソフトピアジャパンと書慶應義塾大学藤幡研究室との共同研究の成果として制作され、ソフトピアジャパンセンタービルに設置。2002年まで運用された。2024年版は、「岐阜おおがきビエンナーレ2017」の際、伊村靖子と松井茂が再展示を計画。「清流の国ぎふ」文化祭2024を契機にIAMAS附属図書館に設置された。
                </JBox>
                <EBox>
                  The 1996 version was the result of a joint research project between the Softopia Japan Foundation and the Fujihata Laboratory of Keio
                  University, and was installed in the Softopia Japan Center Building, where it remained until 2002. The 2024 version was planned for
                  reinstallation by Yasuko Imura and Shigeru Matsui on the occasion of the "Gifu Ogaki Biennale 2017". It was actually installed in the IAMAS
                  library on the occasion of the "Gifu, Land of Clear Streams" Cultural Festival 2024. -
                </EBox>
              </Box>
              <Divider mt={"2em"} mb={"1em"} variant={"dashed"}></Divider>
            </Box>
            <Box>
              <JBox fontFamily={"Noto Sans JP Variable"} fontSize={["0.9em", "0.8em"]}>
                《Light on the Net》version 2024
              </JBox>
              <EBox fontFamily={"Noto Sans JP Variable"} fontSize={["0.9em", "0.8em"]} fontWeight={"light"}>
                《Light on the Net》version 2024
              </EBox>
              <JBox>構想：藤幡正樹</JBox>
              <EBox>Conceived by Masaki Fujihata</EBox>
              <JBox>実装：川嶋岳史、西田騎夕</JBox>
              <EBox>Implementation: Takeshi Kawashima, Kiyu Nishida</EBox>
              <JBox>協力：冨田太基</JBox>
              <EBox>Cooperation: Taiki Tomita</EBox>
              <JBox>制作：情報科学芸術大学院大学［IAMAS］</JBox>
              <EBox>Production: Institute of Advanced Media Arts and Sciences [IAMAS]</EBox>
              <JBox>問い合わせ：ma@iamas.ac.jp</JBox>
              <EBox>Inquiry：ma@iamas.ac.jp</EBox>
            </Box>
          </Box>
        </Box>
        {/* <Box as="i">Light on the Net</Box> project was installed in the Softpia Center Building from 1996 to 1998. It was moved and reinstalled in 2024 at the
        library of the Institute of Advanced Media Arts and Sciences in Ogaki, Gifu Prefecture, Japan. You can see the actual system at the library.
        <br />
        <Box as="span" fontFamily={"Noto Sans JP Variable"} fontSize={["0.9em", "0.8em"]} fontWeight={"light"}>
          Light on the Net
          projectは、1996年から1998年にかけてソフトピアセンタービルに設置されていましたが、2024年に岐阜県大垣市にある情報科学芸術大学院大学の図書館に移設、再駆動されました。図書館で実物を見ることができます。
          <br />
          <Link href={"https://www.iamas.ac.jp/lib/"}>https://www.iamas.ac.jp/lib/</Link>
        </Box> */}
      </Box>
    </Box>
  );
};
