import { useEffect, useState } from "react";

export const useAspectRatio = () => {
  const [aspect, setAspect] = useState(16 / 9);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setAspect(1.5 / 2);
      } else {
        setAspect(16 / 9);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return aspect;
};
