import { AspectRatio, Box, Image, Table, Th, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ClickableCircle } from "../components/ClickableCircle";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { LONManager } from "../lib/LonManager";
import { useAspectRatio } from "../lib/Tool";

export const LightOnTheNet = () => {
  // lon
  const [lonManager, setLonManager] = useState<LONManager | null>(null);
  const [recentAccessList, setRecentAccessList] = useState<Array<Array<string>> | null>(null);
  const [lonImageSrc, setLonImageSrc] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const lon = new LONManager(false, setRecentAccessList, setLonImageSrc, setErrorMessage, setIsLoading);
    lon.request(-1);
    setLonManager(lon);
  }, []);

  const onClickLight = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const idx = event.currentTarget.getAttribute("data-light-index");
    lonRequest(Number(idx));
  };

  const lonRequest = (idx: number) => {
    if (lonManager != null) {
      lonManager.request(idx);
    }
  };

  // common css
  const mx = "1em";
  const aspect = useAspectRatio();

  return (
    <Box>
      <Header mx={mx}></Header>
      <Box className="body" /*maxW={"1440px"}*/ mx={"auto"}>
        <Box className="image" pos={"relative"}>
          <AspectRatio ratio={aspect}>
            <Image src={lonImageSrc}></Image>
          </AspectRatio>
          {isLoading && (
            <AspectRatio ratio={aspect} pos={"absolute"} left={0} top={0} width={"100%"} bgColor={"black"} zIndex={10} opacity={0.5}>
              <Box className="loaderWrapper"></Box>
            </AspectRatio>
          )}
          <AspectRatio ratio={aspect} pos={"absolute"} left={0} top={0} width={"100%"}>
            <Box className="errorMessage">{errorMessage}</Box>
          </AspectRatio>
          <AspectRatio ratio={aspect} pos={"absolute"} left={0} top={0} width={"100%"}>
            <Box className="clickable" onClick={() => lonRequest(-1)} _hover={{ cursor: "pointer" }}>
              <ClickableCircle ratio={aspect} top="6.35%" left={"35.65%"} onClick={onClickLight} data-light-index="0"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"12.1%"} left={"35.65%"} onClick={onClickLight} data-light-index="1"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"17.7%"} left={"35.9%"} onClick={onClickLight} data-light-index="2"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"23.2%"} left={"35.9%"} onClick={onClickLight} data-light-index="3"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"28.9%"} left={"36.1%"} onClick={onClickLight} data-light-index="4"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"34.35%"} left={"36.1%"} onClick={onClickLight} data-light-index="5"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"39.7%"} left={"36.2%"} onClick={onClickLight} data-light-index="6"></ClickableCircle>

              <ClickableCircle ratio={aspect} top={"6.35%"} left={"39.9%"} onClick={onClickLight} data-light-index="7"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"12.1%"} left={"39.9%"} onClick={onClickLight} data-light-index="8"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"17.7%"} left={"40%"} onClick={onClickLight} data-light-index="9"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"23.2%"} left={"40.1%"} onClick={onClickLight} data-light-index="10"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"28.9%"} left={"40.2%"} onClick={onClickLight} data-light-index="11"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"34.4%"} left={"40.2%"} onClick={onClickLight} data-light-index="12"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"39.7%"} left={"40.3%"} onClick={onClickLight} data-light-index="13"></ClickableCircle>

              <ClickableCircle ratio={aspect} top={"6.35%"} left={"44.3%"} onClick={onClickLight} data-light-index="14"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"12.1%"} left={"44.1%"} onClick={onClickLight} data-light-index="15"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"17.9%"} left={"44.3%"} onClick={onClickLight} data-light-index="16"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"23.5%"} left={"44.3%"} onClick={onClickLight} data-light-index="17"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"29%"} left={"44.3%"} onClick={onClickLight} data-light-index="18"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"34.5%"} left={"44.3%"} onClick={onClickLight} data-light-index="19"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"39.7%"} left={"44.3%"} onClick={onClickLight} data-light-index="20"></ClickableCircle>

              <ClickableCircle ratio={aspect} top={"6.35%"} left={"48.5%"} onClick={onClickLight} data-light-index="21"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"12.1%"} left={"48.5%"} onClick={onClickLight} data-light-index="22"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"17.9%"} left={"48.5%"} onClick={onClickLight} data-light-index="23"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"23.5%"} left={"48.5%"} onClick={onClickLight} data-light-index="24"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"29%"} left={"48.5%"} onClick={onClickLight} data-light-index="25"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"34.5%"} left={"48.5%"} onClick={onClickLight} data-light-index="26"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"39.7%"} left={"48.5%"} onClick={onClickLight} data-light-index="27"></ClickableCircle>

              <ClickableCircle ratio={aspect} top={"6.35%"} left={"52.8%"} onClick={onClickLight} data-light-index="28"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"12.1%"} left={"52.7%"} onClick={onClickLight} data-light-index="29"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"17.85%"} left={"52.65%"} onClick={onClickLight} data-light-index="30"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"23.4%"} left={"52.7%"} onClick={onClickLight} data-light-index="31"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"29%"} left={"52.6%"} onClick={onClickLight} data-light-index="32"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"34.5%"} left={"52.6%"} onClick={onClickLight} data-light-index="33"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"39.8%"} left={"52.5%"} onClick={onClickLight} data-light-index="34"></ClickableCircle>

              <ClickableCircle ratio={aspect} top={"6.35%"} left={"57%"} onClick={onClickLight} data-light-index="35"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"12.1%"} left={"56.8%"} onClick={onClickLight} data-light-index="36"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"17.85%"} left={"56.8%"} onClick={onClickLight} data-light-index="37"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"23.4%"} left={"56.8%"} onClick={onClickLight} data-light-index="38"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"29%"} left={"56.7%"} onClick={onClickLight} data-light-index="39"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"34.5%"} left={"56.6%"} onClick={onClickLight} data-light-index="40"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"39.8%"} left={"56.5%"} onClick={onClickLight} data-light-index="41"></ClickableCircle>

              <ClickableCircle ratio={aspect} top={"6.35%"} left={"61.3%"} onClick={onClickLight} data-light-index="42"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"12.1%"} left={"61.2%"} onClick={onClickLight} data-light-index="43"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"17.85%"} left={"61%"} onClick={onClickLight} data-light-index="44"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"23.4%"} left={"60.9%"} onClick={onClickLight} data-light-index="45"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"29%"} left={"60.8%"} onClick={onClickLight} data-light-index="46"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"34.5%"} left={"60.8%"} onClick={onClickLight} data-light-index="47"></ClickableCircle>
              <ClickableCircle ratio={aspect} top={"39.8%"} left={"60.7%"} onClick={onClickLight} data-light-index="48"></ClickableCircle>
            </Box>
          </AspectRatio>
          <Box pt={0} textAlign={"right"} color={"black"} mx={mx} fontWeight={"light"} lineHeight={"1.5em"} textColor={"white"} fontSize={["sm", "md"]}>
            &copy; Masaki Fujihata + IAMAS
          </Box>
        </Box>
        <Box className="content-wrapper">
          <Box className="content" maxW={"1000px"} mx={"auto"} bgColor={"rgb(64,63,63)"}>
            <Box className="access" maxW={"600px"} mx={"auto"} my={["1em", "3em"]} mb={"2em"}>
              <Box p={"1 0"} ml={".5em"}>
                Last {recentAccessList ? recentAccessList.length : 100} accesses:
              </Box>
              <Box overflowX={"scroll"}>
                <Table variant={"unstyled"} bgColor={"black"}>
                  {recentAccessList &&
                    recentAccessList.map((accessInfo: Array<string>) => {
                      const [date, domain, lightState] = accessInfo;
                      return (
                        <Tr key={date}>
                          <Th
                            textTransform={"unset"}
                            letterSpacing={"unset"}
                            fontSize={["md", "lg"]}
                            fontWeight={"unset"}
                            whiteSpace={"nowrap"}
                            pl={".5em"}
                            py={0}
                            pr={0}
                          >
                            {date}
                          </Th>
                          <Th
                            textTransform={"unset"}
                            letterSpacing={"unset"}
                            fontSize={["md", "lg"]}
                            textAlign={"right"}
                            fontWeight={"unset"}
                            whiteSpace={"nowrap"}
                            p={0}
                          >
                            <Box ml={"1em"}>{domain}</Box>
                          </Th>
                          <Th textTransform={"unset"} letterSpacing={"unset"} fontSize={["md", "lg"]} fontWeight={"unset"} whiteSpace={"nowrap"} p={1}>
                            <Box ml={"1em"}>{lightState}</Box>
                          </Th>
                        </Tr>
                      );
                    })}
                </Table>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer></Footer>
      </Box>
    </Box>
  );
};
