import { Box, BoxProps } from "@chakra-ui/react";

interface CustomBoxProps extends BoxProps {
  top: string;
  left: string;
  ratio: number;
  borderRadius?: string; // 任意のプロパティに設定、デフォルトは 50%
}

function adjustLeft(value: string, ratio: number) {
  const v = Number(value.replace("%", ""));
  return `${((v / ratio) * 4) / 3 + ((1 - ((1 / ratio) * 4) / 3) / 2) * 100}%`;
}

function adjustWidth(value: string, ratio: number) {
  const v = Number(value.replace("%", ""));
  return `${((v / ratio) * 4) / 3}%`;
}

export const ClickableCircle: React.FC<CustomBoxProps> = ({ top, left, ratio, ...props }) => {
  return (
    <Box
      position={"absolute"}
      width={adjustWidth("3.7%", ratio)}
      aspectRatio={1}
      top={top}
      left={adjustLeft(left, ratio)}
      borderRadius={"50%"}
      cursor={"pointer"}
      // bgColor={"white"}
      {...props}
    >
      {props.children}
    </Box>
  );
};
