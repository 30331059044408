import { AspectRatio, Box, BoxProps, Image, Link } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

interface OpenWindowProps extends BoxProps {
  href: string;
  windowW: number;
  windowH: number;
}

export const StrongBox: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box {...rest} as={"i"} fontWeight={"medium"}>
      {children}
    </Box>
  );
};

export const About = ({ lang = "jp" }: { lang?: string }) => {
  const mx = "1em";
  const query = useLocation().search;

  const JBox: React.FC<BoxProps> = ({ children, ...rest }) => {
    return (
      <Box {...rest} display={!query.includes("lang=en") ? "block" : "none"} fontFamily={"Noto Sans JP Variable"} fontSize={["0.9em", "0.8em"]}>
        {children}
      </Box>
    );
  };

  const EBox: React.FC<BoxProps> = ({ children, ...rest }) => {
    return (
      <Box {...rest} display={query.includes("lang=en") ? "block" : "none"} fontFamily={"Lato"} fontSize={["0.95em", "0.85em"]} fontWeight={"light"}>
        {children}
      </Box>
    );
  };

  const OpenWindow: React.FC<OpenWindowProps> = ({ href, windowW, windowH, children, ...rest }) => {
    const openWindow = () => {
      window.open(href, "_blank", `width=${windowW},height=${windowH},noopener`);
    };

    return (
      <Box
        as={Link}
        onClick={openWindow}
        textDecoration={"underline"}
        textDecorationThickness={"1px"}
        _hover={{ textDecoration: "underline", cursor: "pointer", textColor: "#b9b9b9" }}
        {...rest}
      >
        {children}
      </Box>
    );
  };

  const playerRef = useRef(null);
  const url =
    "https://www.youtube.com/embed/live_stream?channel=UC3HX84Ny8ZTmwbyTr6yABwg&autoplay=1&mute=1&controls=0&playsinline=1&fs=0&rel=0&modestbranding=1&iv_load_policy=3&enablejsapi=1";
  const [youtubeURL, setYoutubeURL] = useState(url);
  const youtubePlayer = useRef<any>(null);

  useEffect(() => {
    const tag = document.createElement("script");

    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      youtubePlayer.current = new window.YT.Player(playerRef.current, {
        events: {
          // onReady: onPlayerReady,
          // onStateChange: onPlayerStateChange,
          // onError: onError,
        },
        playerVars: {
          playsinline: 1,
        },
      });

      function loadVideo() {
        setYoutubeURL(url + `&timestamp=${Date.now()}`);
      }

      const intervalId = setInterval(() => {
        if (youtubePlayer.current && youtubePlayer.current.getPlayerState) {
          const state = youtubePlayer.current.getPlayerState();
          console.log({ state });
          if (state == -1 || state == 0 || state == 5) {
            //|| state == 2
            loadVideo();
          }
        }
      }, 10 * 1000);

      // function onPlayerReady(event) {
      //   event.target.mute();
      //   event.target.playVideo();

      //   setInterval(() => {
      //     if (!lonManager.hasError && (player.getPlayerState() == 2 || player.getPlayerState() == -1)) {
      //       loadingWrapper.classList.add("loader_active");
      //       errorMessage.innerHTML = "Please Click <span style='text-decoration:underline; cursor:pointer' onClick='PlayVideo();'>Here</span> to Play Stream";
      //     }
      //   }, 1 * 1000);
      // }

      function onPlayerStateChange(event: any) {
        console.log("YT: " + event.data);
        if (event.data == 0) {
          // 配信終了
          console.log("end");
          loadVideo();
          setTimeout(loadVideo, 10 * 1000);
        }

        if (event.data == 3) {
          // バッファリング中（配信終了の可能性あり）
          // バッファリング1分くらい → endイベント
        }

        // if (!lonManager.hasError && event.data == 2) {
        //   loadingWrapper.classList.add("loader_active");
        //   errorMessage.innerHTML = "Please Click <span style='text-decoration:underline' onClick='PlayVideo();'>Here</span> to Play Stream";
        // }

        // if (!lonManager.hasError && event.data == 1) {
        //   loadingWrapper.classList.remove("loader_active");
        //   errorMessage.innerHTML = "";
        // }
      }

      function onError(event: any) {
        console.log(event.data);
        if (event.data == 150) {
          // 配信が既に終了している
          // console.log(event.data);
          setTimeout(loadVideo, 10 * 1000);
        }
      }
    };
  }, []);

  return (
    <Box>
      <Header mx={mx}></Header>
      <Box>
        <AspectRatio ratio={16 / 9}>
          <Box as="iframe" ref={playerRef} id="player" width="100%" height="100%" src={youtubeURL}></Box>
        </AspectRatio>
        <Box pt={0} textAlign={"right"} color={"black"} mx={mx} lineHeight={"1.5em"} fontSize={["sm", "md"]} textColor={"white"}>
          <Box as="span">&copy; Masaki Fujihata + IAMAS</Box>
        </Box>
      </Box>
      <Box>
        <Box maxW={"900px"} mx={"auto"} bgColor={"black"} mt={["3em"]} pb={"3em"}>
          <Box mx={mx} fontWeight={"light"} pt={"1em"}>
            <Box className="access" mb={["3em"]}>
              <JBox>
                《Light on the Net》とは、49個の電灯をインターネット経由で、点けたり消したり出来るプロジェクトです。
                <br />
                1996年10月のソフトピアセンタービルのオープンに合わせて、慶應義塾大学藤幡研究室と財団法人ソフトピアジャパンの共同研究の成果物として、1階エントランスホールに設置されました。2002年6月に一旦停止しましたが、2017年に電灯の駆体が発見され、その後の2024年3月にIAMAS図書館内に再設置されました。
              </JBox>
              <EBox>
                <StrongBox>Light on the Net</StrongBox> project allows users to turn on and off 49 lights via the web. <br />
                It was originally installed in the entrance hall on the ground floor of the Softopia Center building when it opened in October 1996, as a
                product of joint research between the Fujihata Laboratory of Keio University and the Softopia Japan Foundation. It was temporarily shut down in
                June 2002, but an electric light drive was discovered in 2017, and it was reinstalled in the IAMAS library in March 2024.
              </EBox>
            </Box>
            <AspectRatio ratio={606 / 866} w={["80%", "60%"]} mx={"auto"}>
              <Image src="/src/img/LonScreen.jpg" alt="LonScreen.jpg"></Image>
            </AspectRatio>
            <Box my={["1em"]} mb={"3em"}>
              <JBox>
                WebブラウザーNetscapeのスクリーン・キャプチャーです。1998年4月30日の日付が見えます。当時の遅い回線速度ではビデオのストリーミングは困難で、アナログのビデオカメラからの信号をデジタイズした静止画が使われています。解像度は320x240でした。
              </JBox>
              <EBox>
                Screenshot from the Netscape web browser showing the date April 30, 1998. Streaming video was not supported at the low connection speeds of the
                time, so digitized stills from an analog video camera signal were used. The resolution was 320x240.
              </EBox>
            </Box>
            <AspectRatio w={["80%", "60%"]} mx={"auto"}>
              <Image src="/src/img/IMG_0416.JPG" alt="Netscape screen capture"></Image>
            </AspectRatio>
            <Box my={["1em"]} mb={"3em"}>
              <JBox>
                2001年2月6日に撮影したソフトピアセンタービル1階エントランスの写真です。左奥には織田信長の像が見えます。電灯ユニットは奥の大きな空間と一段低い天井との境目に設置されていました。このプロジェクトは、この電灯ユニットとインターネット上のウェブサイトによって構成されていました。
              </JBox>
              <EBox>
                Photo of the ground floor entrance of the Softopia Center building taken on February 6, 2001. A statue of Nobunaga Oda can be seen on the far
                left. The electric light unit was installed at the boundary between the large room in the back and the lower ceiling. The project consisted of
                this electric light unit and a website on the Internet.
              </EBox>
            </Box>
            <AspectRatio ratio={4 / 3} width={"90%"} mx={"auto"} my={["1em"]}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/PU6QfzftbUY?si=b6es0qfm6xDgj_Z0"
                title="当時のスクリーンキャプチャ動画"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </AspectRatio>
            <Box my={["1em"]} mb={"3em"}>
              <JBox>
                1996年頃、実際にアクセスしている様子が記録された動画が出てきました。反応が返ってくるまでに、およそ15秒かかっていることが判ります。この時間の長さが当時のインターネットの速度（＝距離）なのです。{" "}
                <br />
                現場のサーバーがコマンドを受信し、電球の点灯/消灯を制御してから、ビデオから画像をキャプチャーしてJpeg圧縮、それをHTMLファイルに組み込んで返信する作業が全部でおよそ4秒、さらに藤沢と大垣のソフトピアまでの往復の通信時間が追加されて、その合計時間が15秒だったのです。
              </JBox>
              <EBox>
                I found a recorded video that contains the moment of access to the site around 1996. The video shows that it took about 15 seconds to respond.
                This length of the duration is the speed, which is equal to the distance of the Internet at that time. <br />
                So when the local server receives a command from a remote user, then changes the status of the lamp, captures the image from video, then
                converts to Jpeg file, and more to construct an html file for sending back to the remote user. It takes 4 seconds and adds the network speed,
                which is about the distance between Keio University in Fujisawa and Softopia in Ogaki. The total result was 15 seconds.
              </EBox>
            </Box>
            <AspectRatio w={["80%", "60%"]} mx={"auto"} ratio={2480 / 3508}>
              <Image src="/src/img/diagram.jpeg" alt="diagram.jpeg"></Image>
            </AspectRatio>
            <Box my={["1em"]} mb={"3em"}>
              <JBox>
                当時のネットワーク構成図です。WIDEをバックボーンとしてソフトピアと湘南藤沢キャンパス間が、1.5Mbpsの専用線で繋がれていたようです。WIDEのバックボーンから出ること無く専用線を使っても、通信だけでおよそ11秒(サーバーサイドの処理を除く)かかっていることになります。{" "}
                <br />
                計算してみると、画像サイズが320x240で各ピクセルが8bit(Jpeg圧縮があるとして)、合計で614400(0.6Mbit)のファイルサイズ。1.5Mbpsの回線がフルに使えたとして、片道2.5秒かかる計算になるので、実際には2割程度の帯域しか出なかったとすれば12.5秒になるので、凡そ計算は合います。
              </JBox>
              <EBox>
                This was a network diagram in the 90s around Softopia. Two locations, Softopia and SFC Keio University were connected by the 1.5Mbps leased
                line. The WIDE network was also used as a backbone to connect to the Internet. Even using a leased line without the backbone for the Internet,
                it took about 11 seconds, which is surprisingly slow. <br />
                Let us calculate the numbers, if the image resolution is 320x240 with 8 bit, the sum is 614400 bit (0.6Mbit) file size. If it can use the full
                band of the line (1.5 Mbps), one way takes 2.5 seconds. If the actual speed is about 20%, it took 12.5 seconds. This looks good.
              </EBox>
            </Box>
            <AspectRatio ratio={16 / 9} width={"90%"} mx={"auto"} my={["1em"]}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/3RJCap4rB2E?si=dHaeuMJdEZZAEImo"
                title="アーカイブデータ動画"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </AspectRatio>
            <Box my={["1em"]} mb={"3em"}>
              <JBox>
                2017年の大垣ビエンナーレの際に作成した動画です。ハードディスクに保存されていた、1999年3月のキャプチャー画像を元に作成しました。動画は記録された時間に合わせてマッピングしてあり、時間を2分を1秒（120分の1）に圧縮してあります。複数の参加者が同時にアクセスしながら、電球の取り合いをしている状態を見ることができます。
              </JBox>
              <EBox>
                This video was made for the presentation at the Ogaki Biennale 2017. It was created from images taken in March 1999 and stored on a hard drive.
                The video has been mapped to the time it was taken, and the duration has been compressed from two minutes to one second (1/120th of a second).
                Several participants can be seen struggling for the light bulbs while simultaneously accessing them.
              </EBox>
            </Box>
            <AspectRatio ratio={4 / 3} width={"90%"} mx={"auto"} my={["1em"]}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/RTBQvnGOopo?si=lmhdavYFq1QtjkS5"
                title="SFCでの展示動画"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </AspectRatio>
            <Box my={["1em"]} mb={"3em"}>
              <JBox>
                1995年当時の藤幡研究室は、「インターネットの可能性を開発する」ことを研究テーマとしていました。1995年の春学期のゼミの課題「照明をつくる」において、学部3年生であった祐川良子の「共通認識具象化装置」のアイデアがおもしろく、他のゼミ生の協力を得て、インターネットへと拡大し、1995年7月3日から5日まで、慶應義塾大学湘南藤沢キャンパスで「照明をつくる」展として展示されました。
              </JBox>
              <EBox>
                In 1995, the Fujihata Lab's research theme at the time was "Developing the Potential of the Internet," and in the "Creating Lighting" seminar
                project in the spring semester of 1995, Ryoko Sukegawa, a third-year undergraduate student, had an interesting idea for a "common perception
                figurative device," and with the help of other seminar students, it was extended to the Internet and exhibited as the "Creating Lighting"
                exhibition at Keio University's Shonan Fujisawa Campus from July 3 to 5, 1995.
              </EBox>
            </Box>
            <AspectRatio ratio={4 / 3} width={"90%"} mx={"auto"} my={["1em"]}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/YgAr4zZnNFg?si=PFzfiA7F3ga7-aph"
                title="搬入風景動画"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </AspectRatio>
            <Box my={["1em"]} mb={"1em"}>
              <JBox>
                その後、これを元に恒常的な装置としてソフトピアセンタービルに設置することが決まり、1996年6月7日から9月17日の間に搬入作業があったようです。10月のビルのオープニングから常設展示されました。上記の動画には搬入風景と、伊藤俊治さんによるインタビューが含まれています。{" "}
                <br />
                また、祐川良子は1997年、学部4年時に書いた論考で、このプロジェクトについて「ネットワークを介してここで出会い、電球の点滅によって会話する。光のお祭りのように。しかしこの仮想の空間のむこうには本物の電球が点滅している。この不思議な感覚、聖なる物に触れるかのような感覚。私は後にお寺で神様を迎えるために点される多くのお供えのろうそくを見て、そこに込められる祈りや魂を考えた時にこの作品を思い出したのだった。」と書き、クリックの瞬間には、そこに一瞬だけ聖なる空間が開くのではないか、と単なる工学的な可能性以上の展望を、文化人類学的な視点から書いていました。{" "}
                <br />
                岐阜おおがきビエンナーレ2017は、「新しい時代
                メディア・アート研究事始め」というテーマのもと、メディアアートの研究を開くために必要なアーカイブとその再生について、3人の作家、藤幡正樹、三輪眞弘、久保田晃弘らの実作が取り上げられ、展示とともに、複数のシンポジウムが開かれました。シンポジウム「藤幡正樹《Light
                on the Net》を解読する」では、松井茂のモデレーションのもと、飯田豊、喜多千草、篠原資明、藤幡正樹が参加しました。
              </JBox>
              <EBox>
                It was then decided to install it as a permanent installation in the Softopia Center building, and it appears that the installation work was
                done between June 7 and September 17, 1996; it has been on permanent display since the building opened in October. The video above includes
                scenes from the installation and an interview with Toshiharu Ito. <br />
                In an essay written by Ryoko Sukegawa in 1997, during her fourth year of undergraduate studies, she wrote about the project: ‘We meet here
                through the network and converse through the flashing of light bulbs. Like a festival of light. But beyond this virtual space, real light bulbs
                are blinking. This strange sensation, as if I was touching something sacred. I was reminded of this work later when I saw the many offering
                candles lit in temples to welcome the gods and thought of the prayers and souls that are put into them.’ She wrote from a cultural
                anthropological perspective, with the prospect of more than mere technical possibilities, that at the moment of the click, a sacred space might
                open up there for a moment.
                <br />
                The Gifu Ogaki Biennial 2017 was held under the theme "A New Era: Starting Media Art Research' and featured several artworks by three artists,
                Masaki Fujihata, Masahiro Miwa, and Akihiro Kubota, on the archive and its reproduction necessary to start media art research through an
                exhibition and symposiums. One of the symposiums was "Decoding Masaki Fujihata's <StrongBox> Light on the Net</StrongBox>," moderated by Shigeru
                Matsui, with Yutaka Iida, Chigusa Kita, Yoshiaki Shinohara, and Masaki Fujihata.
              </EBox>
            </Box>
            <Box>
              <OpenWindow href="/src/pdf/IAMAS_紀要_2017.pdf" windowW={1920 / 2} windowH={1080}>
                <JBox> IAMAS 2017年 紀要 </JBox>
                <EBox> IAMAS Journal 2017 </EBox>
              </OpenWindow>
            </Box>
            <Box my={["1em"]} mb={"3em"}>
              <JBox>
                IAMASは、2024年10月開催の「清流の国ぎふ」への参加を決め、「DX時代のメディア表現──新しい日常から芸術を思考する」という展示が企画され、その中で《Light
                on the
                Net》が再展示されることになりました。現代のネットワーク環境に即した高解像度の静止画と高解像度のストリーミングを可能とするシステムが設計されました。
              </JBox>
              <EBox>
                In 2023, IAMAS decided to participate in a major festival organized by Gifu Prefecture, "Gifu, Land of Clear Streams" Cultural Festival 2024,
                scheduled to be held in 2024, and decided to hold an exhibition in October entitled "Media Expression in the Age of DX - Thinking about Art from
                a New Everyday Life", in which Light on the Net was selected to be reinstalled. A system designed to provide high-resolution still images and
                high-resolution streaming in line with today's network environment.
              </EBox>
            </Box>
            <AspectRatio w={["80%", "60%"]} mx={"auto"} ratio={4 / 3}>
              <Image src="/src/img/P3190058.jpg" alt="搬入現場写真"></Image>
            </AspectRatio>
            <Box my={["1em"]} mb={"3em"}>
              <JBox>
                2024年3月25日の図書館への搬入作業の様子。岐阜おおがきビエンナーレ2017でのシンポジウムでは、1996年のインターネットが持つ、独特の遅延はもう2度と経験できないという議論から、再設置の賛否について大きく揺れていたのですが、実際に駆動してみると、不思議なことに相変わらず新鮮な体験をもたらしてくれました。
              </JBox>
              <EBox>
                Photo of the installation process on March 25, 2024 in the library. During the symposium of The Gifu Ogaki Biennial 2017, there were many
                arguments for and against the reinstallation of the Internet of 1996, based on the argument that the unique latency of the Internet could never
                be experienced again. However, when it was actually driven, it strangely provided an experience as fresh as ever.
              </EBox>
            </Box>
            <Box my={["1em"]} mb={"3em"}>
              <JBox>External links: </JBox>
              <EBox>External links: </EBox>
              <Box>
                <OpenWindow href="https://www.iamas.ac.jp/newnormal/" windowW={window.innerWidth} windowH={window.innerHeight}>
                  <JBox>「清流の国ぎふ」文化祭2024「DX時代のメディア表現──新しい日常から芸術を思考する」</JBox>
                  <EBox>
                    "Gifu, Land of Clear Streams" Cultural Festival 2024, "Media Expression in the Age of DX - Thinking about Art from a New Everyday Life"
                  </EBox>
                </OpenWindow>
              </Box>
              <Box>
                <OpenWindow href="https://www.iamas.ac.jp/biennale17/" windowW={window.innerWidth} windowH={window.innerHeight}>
                  <JBox>「岐阜おおがきビエンナーレ2017」-- 新しい時代とメディア・アート研究事始め --</JBox>
                  <EBox>The Gifu Ogaki Biennial 2017 –A New Era: Starting Media Art Research–</EBox>
                </OpenWindow>
              </Box>
              {/* <Box>
                <OpenWindow href="https://www.iamas.ac.jp/" windowW={window.innerWidth} windowH={window.innerHeight}>
                  情報科学芸術大学院大学 [IAMAS]
                </OpenWindow>
              </Box>
              <Box>
                <OpenWindow href="https://www.iamas.ac.jp/lib/" windowW={window.innerWidth} windowH={window.innerHeight}>
                  情報科学芸術大学院大学附属図書館
                </OpenWindow>
              </Box>
              <Box>
                <OpenWindow href="https://www.fujihata.jp/" windowW={window.innerWidth} windowH={window.innerHeight}>
                  藤幡正樹
                </OpenWindow>
              </Box> */}
            </Box>
          </Box>
        </Box>
        <Footer></Footer>
      </Box>
    </Box>
  );
};
