export class LoadManager {
	constructor(setIsLoading) {
		setIsLoading(true);
		this.reqTimestamp = new Date();
		this.waitTime = 0;
		this.setIsLoading = setIsLoading;
	}

	hideCover() {
		this.resTimestamp = new Date();
		let elapsedTime = this.resTimestamp - this.reqTimestamp;
		let restTime = Math.max(this.waitTime - elapsedTime, 0);

		setTimeout(() => {
			// loadingWrapper.classList.remove("loader_active");
			this.setIsLoading(false);
		}, restTime)
	}

	showCover() {
		// loadingWrapper.classList.add("loader_active");
		this.setIsLoading(true);
	}

	setHistory(_history, _lonManager) {
		this.history = _history;
		this.lonManager = _lonManager;
		this.lonManager.showRecentAccess(this.history);
	}
}
