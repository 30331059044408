import { Box, ChakraProvider } from "@chakra-ui/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { About } from "./pages/About";
import { Chat } from "./pages/Chat";
import { LightOnTheNet } from "./pages/LightOnTheNet";
import { theme } from "./Theme";

export const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LightOnTheNet />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/chat",
      element: <Chat />,
    },
  ]);

  return (
    <ChakraProvider theme={theme}>
      <Box /*maxW={"1000px"} mx={"auto"}*/>
        <RouterProvider router={router} />
      </Box>
    </ChakraProvider>
  );
};
