import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack, IconButton, Link, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

// const Nav = ({ path }: { path: string }) => {
//   // if (path.includes("about")) {
//   //   return (
//   //     <LinkOverlay href="/" _hover={{ textDecoration: "underline" }}>
//   //       Main
//   //     </LinkOverlay>
//   //   );
//   // } else {
//   //   return (
//   //     <LinkOverlay href="/about" _hover={{ textDecoration: "underline" }}>
//   //       About
//   //     </LinkOverlay>
//   //   );
//   // }

//   return (
//     <>
//       <LinkOverlay href="/" _hover={{ textDecoration: "underline" }}>
//         Main
//       </LinkOverlay>
//       <LinkOverlay href="/about" _hover={{ textDecoration: "underline" }}>
//         About
//       </LinkOverlay>
//       <LinkOverlay href="/archive" _hover={{ textDecoration: "underline" }}>
//         Archive
//       </LinkOverlay>
//     </>
//   );
// };

export const Header = ({ mx }: { mx: string }) => {
  const path = useLocation().pathname.toLowerCase();
  const query = useLocation().search;
  const handleMenuClick = (url: string) => {
    window.open(`${url}`, "_blank", "width=720, height=720");
  };

  return (
    <HStack className="head" justifyContent={"space-between"} mx={mx}>
      <Box className="title" w={"100%"} mt={["1em", ".9em"]} mb={["0.2em", "0.5em"]}>
        <Heading fontWeight={"light"} size={["md", "lg"]} w={"100%"}>
          <Box as="span" mr={".5em"}>
            Light on the Net Project
          </Box>
          <Box as={"span"} fontSize={["sm", "sm"]} display={"inline-block"} mr={"1em"}>
            version 2024
          </Box>
        </Heading>
      </Box>
      <HStack className="menu-pc" display={["flex"]} fontSize={["0.9em", "0.9em"]} spacing={[0]} alignItems={"center"}>
        <Box whiteSpace={"nowrap"} display={path.includes("about") || path == "/" ? "block" : "none"} mr={[0]} fontSize={"sm"}>
          <Link
            href={`${path}?lang=en`}
            textDecoration={query.includes("lang=en") ? "unset" : "underline"}
            pointerEvents={query.includes("lang=en") ? "none" : "auto"}
          >
            EN
          </Link>{" "}
          /{" "}
          <Link
            href={`${path.replace("?lang=en", "")}`}
            textDecoration={query.includes("lang=en") ? "underline" : "unset"}
            pointerEvents={query.includes("lang=en") ? "auto" : "none"}
          >
            JP
          </Link>
        </Box>
        <Box className="menu-sp" display={path == "/" ? "block" : "none"}>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
              size={["lg"]}
              colorScheme="white"
              fontSize={["1.5em", "2em"]}
              border={"none"}
              justifyContent={"right"}
              _focus={{
                outline: "none",
              }}
            />
            <MenuList bgColor={"rgb(64, 63, 63)"} p={0}>
              <MenuItem
                bgColor={"rgb(64, 63, 63)"}
                borderRadius={"50%"}
                onClick={() => window.open(`/`, "_blank", "width=480, height=640, noopener")}
                display={path != "/" ? "block" : "none"}
                _hover={{ textDecoration: "underline" }}
              >
                Main
              </MenuItem>
              <MenuItem
                bgColor={"rgb(64, 63, 63)"}
                borderRadius={"50%"}
                onClick={() => window.open(`/about${query}`, "_blank", `width=${window.innerWidth}, height=${window.innerHeight}, noopener`)}
                display={path == "/" ? "block" : "none"}
                _hover={{ textDecoration: "underline" }}
              >
                About
              </MenuItem>
              <MenuItem
                bgColor={"rgb(64, 63, 63)"}
                borderRadius={"50%"}
                onClick={() => window.open(`/chat`, "_blank", "width=480, height=640, noopener")}
                display={path == "/" ? "block" : "none"}
                _hover={{ textDecoration: "underline" }}
              >
                Chat
              </MenuItem>
              <MenuItem
                bgColor={"rgb(64, 63, 63)"}
                borderRadius={"50%"}
                onClick={() =>
                  window.open(`https://www.youtube.com/@LightontheNet-IAMAS/streams`, "_blank", "width=480, height=640, top=0, left=400, noopener")
                }
                display={path == "/" ? "block" : "none"}
                _hover={{ textDecoration: "underline" }}
              >
                Archive
              </MenuItem>
              {/* <LinkBox as={MenuItem} bgColor={"rgb(64, 63, 63)"} borderRadius={"50%"}>
                <LinkOverlay href="/" _hover={{ textDecoration: "underline" }}>
                  Main
                </LinkOverlay>
              </LinkBox>
              <LinkBox as={MenuItem} bgColor={"rgb(64, 63, 63)"} borderRadius={"50%"}>
                <LinkOverlay href="/about" _hover={{ textDecoration: "underline" }} onClick={() => handleMenuClick("/about")}>
                  About
                </LinkOverlay>
              </LinkBox>
              <LinkBox as={MenuItem} bgColor={"rgb(64, 63, 63)"} borderRadius={"50%"}>
                <LinkOverlay href="/chat" _hover={{ textDecoration: "underline" }}>
                  Chat
                </LinkOverlay>
              </LinkBox>
              <LinkBox as={MenuItem} bgColor={"rgb(64, 63, 63)"} borderRadius={"50%"}>
                <LinkOverlay href="/archive" _hover={{ textDecoration: "underline" }}>
                  Archive
                </LinkOverlay>
              </LinkBox> */}
            </MenuList>
          </Menu>
        </Box>
      </HStack>
    </HStack>
  );
};
