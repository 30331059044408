import { Box } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

declare global {
  interface Window {
    YT: any;
    onYouTubeIframeAPIReady: () => void;
  }
}

export const Chat = () => {
  const playerRef = useRef<HTMLDivElement | null>(null); // iframeのdivを参照するためのref
  const [videoId, setVideoId] = useState<string | null>(null);
  const YTPlayer = useRef<any>(null);
  const channelId = "UC3HX84Ny8ZTmwbyTr6yABwg";
  const url = `https://www.youtube.com/embed/live_stream?channel=${channelId}&enablejsapi=1`;
  const [youtubeURL, setYoutubeURL] = useState(url);

  // IFrame APIの準備ができたら呼ばれる関数
  const onYouTubeIframeAPIReady = () => {
    if (playerRef.current) {
      YTPlayer.current = new window.YT.Player(playerRef.current, {
        events: {
          onReady: onPlayerReady,
        },
      });
    }
  };

  // プレイヤーが準備できたら呼ばれる
  const onPlayerReady = (event: any) => {
    const video_id = YTPlayer.current?.getVideoData().video_id;
    setVideoId(video_id);
    // console.log(video_id);
  };

  // YouTube IFrame APIをロードしてプレイヤーを初期化
  useEffect(() => {
    // YouTube IFrame APIのスクリプトを読み込み
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

    // グローバル関数を定義
    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

    setInterval(() => {
      setYoutubeURL(url + `&timestamp=${Date.now()}`);
      onPlayerReady(null);
    }, 60 * 1000);

    return () => {
      // コンポーネントのアンマウント時にグローバル関数をクリーンアップ
      window.onYouTubeIframeAPIReady = () => {};
    };
  }, []);

  return (
    <Box>
      <Header mx="0.5em"></Header>
      <Box>
        <Box ref={playerRef} as="iframe" id="player" width="0" height="0" src={youtubeURL}></Box>
      </Box>
      <Box maxW={"900px"} mx={"auto"} minH={"500px"}>
        {videoId && (
          <Box as="iframe" width="100%" height="500px" src={`https://www.youtube.com/live_chat?v=${videoId}&embed_domain=${window.location.hostname}`}></Box>
        )}
      </Box>
      <Footer></Footer>
    </Box>
  );
};
