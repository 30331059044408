import { extendTheme } from "@chakra-ui/react";
import "@fontsource-variable/noto-sans-jp";
import "@fontsource/lato/300-italic.css";
import "@fontsource/lato/300.css";

export const theme = extendTheme({
  fonts: {
    heading: "Lato, Noto Sans JP Variable, Arial, Helvetica, sans-serif ",
    body: "Lato, Noto Sans JP Variable, Arial, Helvetica, sans-serif",
  },
  styles: {
    global: {
      body: {
        backgroundColor: "rgb(64, 63, 63)",
        color: "white",
        fontSize: ["lg", "xl"],
        lineHeight: "1.5em",
      },
    },
  },
});
