import ReconnectingWebSocket from 'reconnecting-websocket';
import {
	LoadManager
} from "../lib/LoadManager";

export class LONManager {
	constructor(_is_stream_mode = false, setRecentAccessList, setLonImageSrc, setErrorMessage, setIsLoading) {
		this.is_stream_mode = _is_stream_mode;
		this._uuidIdent = false;
		this.hasError = false;
		this.setRecentAccessList = setRecentAccessList;
		this.setLonImageSrc = setLonImageSrc;
		this.setErrorMessage = setErrorMessage;
		this.setIsLoading = setIsLoading;
	}

	formatMonth(strMonth) {
		const mapArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return mapArray[parseInt(strMonth) - 1];
	}

	showRecentAccess(_recentAccessAList) {
		// lonNumRecentAccessSpan.textContent = _recentAccessAList.length;
		// fetch history
		const recentAccessList = _recentAccessAList;
		let recentAccessListContent = [];

		// format history
		for (const element of recentAccessList.reverse()) {
			const [datetime, _domain, _reqLightIndex, _lightStatus, _uuidIdent] = element;
			const year = datetime.substring(2, 4);
			const month = this.formatMonth(datetime.substring(4, 6));
			const day = datetime.substring(6, 8);
			const hour = datetime.substring(8, 10);
			const minute = datetime.substring(10, 12);
			const second = datetime.substring(12, 14);
			const domain = _domain;
			const reqLightIdx = `(${Math.floor(_reqLightIndex/7)+1}, ${Math.floor(_reqLightIndex%7)+1})`;
			const lightStatus = _lightStatus ? "ON" : "OFF";
			// recentAccessListContent += `<tr><th>[${day}/${month}/${year} ${hour}:${minute}:${second} JST]</th><th>${domain}</th><th>${lightStatus}${reqLightIdx}</th></tr>`;

			let access = [
				`[${day}/${month}/${year} ${hour}:${minute}:${second} JST]`,
				`${domain}`,
				`${lightStatus}${reqLightIdx}`
			];

			recentAccessListContent.push(access);
		}
		// lonRecentAccessListTable.innerHTML = recentAccessListContent;
		this.setRecentAccessList(recentAccessListContent);
	}

	request(_lightIndex) {
		console.log('connecting to the websocket server...')
		// loadingを挿入
		const loadManager = new LoadManager(this.setIsLoading);
		// 通信を開始
		const url = `wss://classic.lightonthe.net/ws/light_request`;
		let sock = null;
		if (this.is_stream_mode) {
			sock = new ReconnectingWebSocket(url);
		} else {
			sock = new WebSocket(url);
		}
		var uuid = null;
		sock.addEventListener("message", e => {
			// console.log("WebSocket: message: " + e.data);

			var json = JSON.parse(e.data);
			console.log(json)

			if (json.action === "connect") {
				console.log('sending a request to the websocket server...');

				uuid = json.uuid;

				if (!this._uuidIdent) {
					this._uuidIdent = json.uuidIdent;
				}
				sock.send(JSON.stringify({
					action: "toggle",
					index: _lightIndex,
					uuid: uuid,
					uuidIdent: this._uuidIdent
				}));

			} else if (json.action == "accept") {
				console.log('connection is accepted');

			} else if (json.action == "status") {
				if (json.requestUUID === uuid) {
					console.log('OK, request completed.');
					if (!this.is_stream_mode) {
						sock.close();
					}
				}

				if (json.status) {
					console.log(json)
					// fetch image
					// if (lonImage != null) {
					// 	lonImage.src = `/image/${json.imagePath}`;
					// }
					this.setLonImageSrc(`${process.env.REACT_APP_HOST}/image/${json.imagePath}`);
					loadManager.setHistory(json.recentAccess, this);
				} else {
					console.log('ERROR: no light control');
					this.setErrorMessage("Server not respond");
					this.hasError = true;
				}
			}
		});

		sock.addEventListener("open", e => {
			console.log('connected to the websocket server');
		});

		sock.addEventListener("close", e => {
			// loading 解除
			if (!this.hasError && !this.is_stream_mode) {
				loadManager.hideCover();
			}
		});

		sock.addEventListener("error", e => {
			console.log('connection ERROR');
			this.setErrorMessage("Connection failed, please refresh");
			loadManager.showCover();
			this.hasError = true;
		});
	}
}
